import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Title } from "../Section"
import Logo from "../../images/logo.svg"

const StyledLogo = styled.img`
  display: block;
  margin: 0 auto 0 auto;
  width: 100%;
`

const PageNotFound = () => (
  <Primary alternate liquid>
    <Row center="xs">
      <Col xs={12} md={10}>
        <Title title="Oops! Something went wrong." alternate />
      </Col>
    </Row>
    <br />
    <Row center="xs">
      <Col xs={10} md={6}>
        <StyledLogo src={Logo} alt="My Ice Man logo" />
      </Col>
    </Row>
  </Primary>
)

export default PageNotFound
